var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"file",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"file__card-wrapper",attrs:{"elevation":hover ? 12 : 5,"height":"30vh"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('v-btn',{style:(hover ? 'display: flex' : 'display: none'),attrs:{"color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.download(_vm.startDate, _vm.endDate)}}},[_vm._v(" "+_vm._s(_vm.text.buttonText)+" ")]),_c('v-icon',{staticClass:"mt-5",style:(hover && _vm.$slots.inputs ? 'display: none;' : 'display: flex'),attrs:{"size":"56"}},[_vm._v(_vm._s(_vm.text.icon))])],1),_c('v-card-text',{staticClass:"my-4 text-center title",style:(hover ? 'display: none' : 'display: block')},[_vm._v(" "+_vm._s(_vm.text.text)+" ")]),_c('v-card-actions',{style:(hover ? 'display: flex; flex-direction: column;' : 'display: none')},[(_vm.withDates)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"return-value":_vm.startDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Начальная дата","prepend-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_vm._t("startTimePicker"),_c('v-date-picker',{attrs:{"max":_vm.endDate,"no-title":"","scrollable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#5d4bd0"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"#5d4bd0"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.startDate)}}},[_vm._v("OK")])],1)],2)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.endDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endDate=$event},"update:return-value":function($event){_vm.endDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Конечная дата","prepend-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_vm._t("endTimePicker"),_c('v-date-picker',{attrs:{"min":_vm.startDate,"no-title":"","scrollable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#5d4bd0"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"#5d4bd0"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.endDate)}}},[_vm._v("OK")])],1)],2)],1)],1):_vm._e(),_vm._t("inputs")],2)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }