<template>
  <v-col cols="12" sm="6" md="3" class="file">
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 12 : 5"
        height="30vh"
        class="file__card-wrapper"
      >
        <v-card-title class="justify-center">
          <v-btn
            color="#5d4bd0"
            dark
            :style="hover ? 'display: flex' : 'display: none'"
            @click="download(startDate, endDate)"
          >
            {{ text.buttonText }}
          </v-btn>
          <v-icon
            size="56"
            class="mt-5"
            :style="hover && $slots.inputs ? 'display: none;' : 'display: flex'"
            >{{ text.icon }}</v-icon
          >
        </v-card-title>
        <v-card-text
          class="my-4 text-center title"
          :style="hover ? 'display: none' : 'display: block'"
        >
          {{ text.text }}
        </v-card-text>
        <v-card-actions
          :style="
            hover ? 'display: flex; flex-direction: column;' : 'display: none'
          "
        >
          <v-row v-if="withDates">
            <v-col cols="6">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Начальная дата"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <slot name="startTimePicker"></slot>
                <v-date-picker
                  v-model="startDate"
                  :max="endDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="#5d4bd0" @click="menu1 = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="#5d4bd0"
                    @click="$refs.menu1.save(startDate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="Конечная дата"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <slot name="endTimePicker"></slot>
                <v-date-picker
                  v-model="endDate"
                  :min="startDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="#5d4bd0" @click="menu2 = false"
                    >Cancel</v-btn
                  >
                  <v-btn text color="#5d4bd0" @click="$refs.menu2.save(endDate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <slot name="inputs"></slot>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
export default {
  props: {
    download: {
      type: Function,
      default: () => {},
    },
    text: {
      type: Object,
      default() {
        return {
          text: "",
          buttonText: "",
          icon: "",
        };
      },
    },
    withDates: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      endDate: new Date().toISOString().substring(0, 10),
      startDate: new Date().toISOString().substring(0, 10),
    };
  },
};
</script>

<style lang="scss">
.file {
  &__card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    :hover {
      height: max-content;
    }
  }
}
</style>
