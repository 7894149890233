var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dark":_vm.$dark.get()}},[_c('v-card-title',{staticClass:"flex justify-center title"},[_c('div',{staticClass:"title__conteiner"},[_c('p',{staticClass:"title__text"},[_vm._v("Отчеты")])]),_c('div',{staticClass:"title__conteiner"},[_c('v-btn',{attrs:{"color":"#5d4bd0","dark":""},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_vm._v("Готовые")])],1)]),_c('v-card-text',[(_vm.loading)?_c('loader'):_vm._e(),_c('clients-list-modal',{attrs:{"show-modal":_vm.showModal,"get-list":_vm.getList()}}),(!_vm.isProvider)?_c('v-row',[_c('ReportFile',{attrs:{"download":_vm.downloadDepartment,"text":{
          text: 'Филиалы',
          buttonText: 'Скачать отчет по филиалам',
          icon: 'mdi-office-building',
        }}}),_c('ReportFile',{attrs:{"download":_vm.downloadProviders,"text":{
          text: 'Поставщики',
          buttonText: 'Скачать отчет по поставщикам',
          icon: 'mdi-account-group',
        }}}),_c('ReportFile',{attrs:{"download":_vm.downloadOrder,"text":{
          text: 'Заказы',
          buttonText: 'скачать отчет по заказам',
          icon: 'mdi-clipboard-list-outline',
        }},scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"статус","items":_vm.allStatus,"item-value":"id","item-text":"title","rules":[_vm.$validate.required]},model:{value:(_vm.input.statusId),callback:function ($$v) {_vm.$set(_vm.input, "statusId", $$v)},expression:"input.statusId"}})],1),(_vm.input.statusId === 666777666)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Филиалы","items":_vm.USER.userInfo.departments,"item-value":"id","item-text":"name","rules":[_vm.$validate.required]},model:{value:(_vm.input.departmentId),callback:function ($$v) {_vm.$set(_vm.input, "departmentId", $$v)},expression:"input.departmentId"}})],1):_vm._e()],1)]},proxy:true}],null,false,2457869700)}),_c('ReportFile',{attrs:{"download":_vm.downloadStatus,"text":{
          text: 'Статусы',
          buttonText: 'скачать отчет по статусам',
          icon: 'mdi-card-text',
        }},scopedSlots:_vm._u([{key:"startTimePicker",fn:function(){return [_c('v-time-picker',{attrs:{"format":"24hr","use-seconds":""},model:{value:(_vm.statusInput.timeStart),callback:function ($$v) {_vm.$set(_vm.statusInput, "timeStart", $$v)},expression:"statusInput.timeStart"}})]},proxy:true},{key:"endTimePicker",fn:function(){return [_c('v-time-picker',{attrs:{"format":"24hr","use-seconds":""},model:{value:(_vm.statusInput.timeEnd),callback:function ($$v) {_vm.$set(_vm.statusInput, "timeEnd", $$v)},expression:"statusInput.timeEnd"}})]},proxy:true}],null,false,1981522505)}),_c('ReportFile',{attrs:{"download":_vm.downloadReward,"text":{
          text: 'Премии',
          buttonText: 'Скачать отчет по премиям',
          icon: 'mdi-cash-100',
        }}}),_c('ReportFile',{attrs:{"download":_vm.downloadClientsReport,"text":{
          text: 'Клиенты',
          buttonText: 'Скачать отчет по клиентам',
          icon: 'mdi-clipboard-account-outline',
        }},scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Период","items":_vm.createPeriods(24)},model:{value:(_vm.clientsInput.lifeTimePeriods),callback:function ($$v) {_vm.$set(_vm.clientsInput, "lifeTimePeriods", $$v)},expression:"clientsInput.lifeTimePeriods"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Отчет по:","items":_vm.clientsPeriod,"item-value":"value","item-text":"name"},model:{value:(_vm.clientsInput.isMonth),callback:function ($$v) {_vm.$set(_vm.clientsInput, "isMonth", $$v)},expression:"clientsInput.isMonth"}})],1)],1)]},proxy:true}],null,false,1728827687)}),_c('ReportFile',{attrs:{"download":_vm.downloadDistanceReport,"text":{
          text: 'Дистанция',
          buttonText: 'Скачать отчет по дистанции',
          icon: 'mdi-bike-fast',
        }}}),_c('ReportFile',{attrs:{"download":_vm.downloadSalesReport,"text":{
          text: 'Продажи',
          buttonText: 'Скачать отчет по продажам',
          icon: 'mdi-cash-register',
        }},scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Тип отчета","items":_vm.salesReportTypes,"item-value":"id","item-text":"name","rules":[_vm.$validate.required]},model:{value:(_vm.salesTypeId),callback:function ($$v) {_vm.salesTypeId=$$v},expression:"salesTypeId"}})],1),(_vm.salesTypeId !== 3)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Филиалы","items":_vm.USER.userInfo.departments,"item-value":"id","item-text":"name","rules":[_vm.$validate.required]},model:{value:(_vm.salesDepartment),callback:function ($$v) {_vm.salesDepartment=$$v},expression:"salesDepartment"}})],1):_vm._e()],1)]},proxy:true}],null,false,3146770836)}),_c('ReportFile',{attrs:{"download":_vm.downloadCompensationReport,"text":{
          text: 'Компенсации работников',
          buttonText: 'Скачать отчет по компенсации',
          icon: 'mdi-compare-horizontal',
        }}}),_c('ReportFile',{attrs:{"download":_vm.downloadAirbaReport,"text":{
          text: 'Airba',
          buttonText: 'Скачать отчет по Airba',
          icon: 'mdi-credit-card',
        }}})],1):_c('v-row',[_c('ReportFile',{attrs:{"download":_vm.downloadSalesReport,"text":{
          text: 'Продажи',
          buttonText: 'Скачать отчет по продажам',
          icon: 'mdi-cash-register',
        }},scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Тип отчета","items":_vm.salesReportTypes.filter(function (item) { return item.id !== 3; }),"item-value":"id","item-text":"name","rules":[_vm.$validate.required]},model:{value:(_vm.salesTypeId),callback:function ($$v) {_vm.salesTypeId=$$v},expression:"salesTypeId"}})],1),(_vm.salesTypeId !== 3)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Филиалы","items":_vm.USER.userInfo.departments,"item-value":"id","item-text":"name","rules":[_vm.$validate.required]},model:{value:(_vm.salesDepartment),callback:function ($$v) {_vm.salesDepartment=$$v},expression:"salesDepartment"}})],1):_vm._e()],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }